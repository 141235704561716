/* eslint-disable no-console */
import detect from 'feature-detect-es6';

detect.fetch = () => {
  try {
    eval('fetch'); /* eslint-disable-line no-eval */
    return true;
  } catch (err) {
    return false;
  }
};

const polyfillScript = document.getElementById('polyfill-script');

if (!detect.all('class', 'spread', 'destructuring', 'let', 'const', 'arrowFunction', 'generators', 'defaultParamValues', 'templateStrings', 'collections', 'newArrayFeatures', 'promises', 'fetch')) {
  const babelPolyfillScript = document.createElement('script');
  babelPolyfillScript.src = '/assets/dist/js/babel-polyfill.min.js';
  document.body.insertBefore(babelPolyfillScript, polyfillScript);
  const fetchScript = document.createElement('script');
  fetchScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/fetch/2.0.3/fetch.min.js';
  document.body.insertBefore(fetchScript, polyfillScript);
}

// Event constructor
const Event = (event, eventParams) => { /* eslint-disable-line no-unused-vars */
  const params = eventParams || { bubbles: false, cancelable: false, detail: undefined };
  const evt = document.createEvent('CustomEvent');
  evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
  return evt;
};


// object-fit fallback
if ('objectFit' in document.documentElement.style === false) {
  const imgs = document.querySelectorAll('.object-fit');
  for (let i = 0; i < imgs.length; i++) { /* eslint-disable-line no-plusplus */
    const wrapper = imgs[i];
    const img = wrapper.getElementsByTagName('img')[0];
    let imgUrl = img.src;
    if (imgUrl === null || imgUrl === '') {
      imgUrl = img.getAttribute('data-src');
    }
    if (imgUrl === null || imgUrl === '') {
      imgUrl = img.getAttribute('data-src-ie11');
    }
    if (imgUrl) {
      wrapper.setAttribute('style', `background-image:url(${encodeURI(imgUrl)});`);
      wrapper.classList.add('compat-object-fit');
    }
  }
}
/* eslint-enable no-console */
